.display-linebreak {
    white-space: pre-line;
}

.status-on{
    background: #83BB25;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 1000px;
}

.info-div{
    margin-bottom: 2rem;
}

.info-div .fa-check{
    font-size: 1.125rem;
    color: #83BB25;
}

/* Tabs */
.toggle-button {
    padding: 0.25rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: fit-content;
    background-color: #ffffff;
    outline: 2px solid #D3D3D4;
    border-radius: 1000px;
    cursor: pointer;
}

.dark-mode .toggle-button {
    background-color: #858585;
    outline: none;
}

.toggle-button span {
    padding: 0.5rem 0.75rem;
    border-radius: 1000px;
    user-select: none;
}

.toggle-button span.active {
    background-color: #83BB25;
    color: #ffffff;
}

/* Forecast */
.great-check{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 104px;
    min-width: 104px;
    max-height: 104px;
    max-width: 104px;
    background-color: #83BB25;
    border-radius: 9999px;
}

.great-check svg.fa-check{
    color: #FFFFFF;
    font-size: 80px;
}

.icon-circle-up{
    background-color: #83BB25;
    color: #ffffff;
    min-height: 48px;
    min-width: 48px;
    max-height: 48px;
    max-width: 48px;
    border-radius: 9999px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-circle-equal{
    background-color: orange;
    color: #ffffff;
    min-height: 48px;
    min-width: 48px;
    max-height: 48px;
    max-width: 48px;
    border-radius: 9999px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-circle-down{
    background-color: #d31616;
    color: #ffffff;
    min-height: 48px;
    min-width: 48px;
    max-height: 48px;
    max-width: 48px;
    border-radius: 9999px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.typewriter{
    border: 1px solid #D3D3D4;
    border-radius: 8px;
    padding: 2rem;
    margin-top: 2rem;
}

.typewriter a svg{
    font-size: 1.5rem;
    color: #83BB25;
    transition: 300ms color;
}

.typewriter a:hover svg{
    color: #76A821;
}

/* Media queries */
@media (max-width: 991.98px) {
    #collapse-info{
        margin-top: 2rem;
    }
}