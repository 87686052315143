.gauge {
  width: 250px;
}

.gauge-body {
  width: 100%;
  background: linear-gradient(90deg, #EE7D11 0%, #3BB141 100%);
  padding-bottom: 50%;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  position: relative;
}

.gauge-cover {
  width: 75%;
  height: 75%;
  background: #ffffff;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
}

.dark-mode .gauge-cover{
  background: #43474E;
}

.gauge-pointer {
  width: 37%;
  height: auto;
  position: absolute;
  bottom: -9.8%;
  left: 50%;
  transform-origin: 0% 50%;
  transform: translateX(-50%) rotate(-90deg);
}