.dark-mode-checkbox {
  opacity: 0;
  position: absolute;
}

.dark-mode-toggle {
  background-color: #22262F;
  width: 56px;
  height: 28px;
  position: relative;
  border-radius: 9999px;
  font-size: 18px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dark-mode-checkbox:checked + .dark-mode-toggle{
  background-color: #ffffff;
} 

.fa-moon {
  color: #22262F;
}

.fa-sun {
  color: #ffffff;
}

.dark-mode-toggle .ball{
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 3px;
  top: 3px;
  border-radius: 100%;
  transition: transform 0.2s linear;
}

.dark-mode-checkbox:checked + .dark-mode-toggle .ball {
  background-color: #22262F;
  transform: translateX(28px);
}

.powered-tsl span, .data-from span {
  font-weight: 500;
  font-size: 9px;
  line-height: 163%;
  text-align: initial;
}

.powered-tsl span{
  margin-bottom: -8px;
}

.data-from span {
  margin-bottom: 4px;
}

.powered-tsl img, .data-from img {
  height: 20px;
  width: auto;
  object-fit: contain;
}

.dark-mode .data-from img {
  filter: brightness(0) invert(1);
}

.step-indicators div.default-node{
  border: 0!important;
}

.content-container{
  display: none;
}

.step-row-2{
  margin-left: auto;
  margin-right: auto;
}

div.default-node.active{
  background-color: #83BB25;
  color: #fff;
}

div.default-node.active ~ div span:nth-child(1){
  background-color: #83BB25;
}

div.step-content{
  width: 624px;
  margin-left: auto;
  margin-right: auto;
}

.slider-nav{
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  margin-top: 0.75rem;
}

.slider-nav button{
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 2rem;
  color: #22262F;
}

ul.slick-dots{
  bottom: -49.5px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

ul.slick-dots li button:before{
  font-size: 16px;
  opacity: 1;
  color: #D3D3D4;
}

ul.slick-dots li.slick-active button:before{
  color: #83BB25;
}

#searching-in-databases svg{
  font-size: 20px;
}

.disabled-step{
  pointer-events: none;
  opacity: 0.25;
}

@media (max-width: 575.98px) {
  div.step-indicators span:nth-child(2){
    width: 24px;
  }

  .stepper .step-row-2 .step-content{
    max-width: 368px!important;
    min-width: 368px!important;
  }
}