.courses{
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.load-more{
  text-align: center;
  position: relative;
}

.load-more a{
  display: initial;
}

.load-more span{
  position: absolute;
  left: 0;
}

.dark-mode .load-more span{
  color: #D3D3D4;
}

/* Media queries */
@media (max-width: 991.98px) {
	.load-more a{
    display: flex;
    justify-content: flex-end;
  } 
}

@media (max-width: 575.98px) {
  .load-more{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .load-more a{
    justify-content: center;
  }

  .load-more span{
    position: relative;
  }
}
