.search-form{
  gap: 32px;
}

.search-form div{
  width: 100%;
}

.search-group {
  width: 100%;
  min-width: none;
}

.search-group .tooltip-btn{
  right: 12px;
}

.search-btn{
  position: absolute;
  right: calc(((100vw - 856px) / 2) - 143.25px);
}

@media (max-width: 1399.98px) { 

  .search-btn{
    right: calc(((100vw - 736px) / 2) - 143.25px);
  }

}

@media (max-width: 1199.98px) {

  .search-btn{
    right: calc(((100vw - 615.98px) / 2) - 143.25px);
  }

}

@media (max-width: 991.98px) {

  .search-btn{
    right: calc(((100vw - 456px) / 2) - 143.25px);
  }

}

@media (max-width: 767.98px) {

  .search-btn{
    position: inherit;
  }

}