/* Imports */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");

/* Global */
body {
  font-family: "Montserrat", sans-serif !important;
}

.App {
  background-color: #f5f3ee;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.dark-mode {
  background-color: #22262f;
}

body button {
  background-color: transparent;
  border: none;
}

body a {
  text-decoration: none;
}

/* Typography */
body h1 {
  font-weight: 700;
  font-size: 2.75rem;
  line-height: 134%;
  text-align: left;
  color: #22262f;
  margin: 0;
}

.dark-mode h1 {
  color: #fff;
}

body h2 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 134%;
  color: #22262f;
  margin: 0;
}

.dark-mode h2 {
  color: #fff;
}

body h3 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 179%;
  color: #22262f;
  margin: 0;
}

.dark-mode h3 {
  color: #fff;
}

body h4 {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 156%;
  color: #22262f;
  margin: 0;
}

.dark-mode h4 {
  color: #fff;
}

body label,
body .form-label {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 156%;
  color: #22262f;
}

body .dark-mode label,
body .dark-mode .form-label {
  color: #fff;
}

body label.form-label {
  font-weight: 700;
  margin: 0;
}

body p,
body input,
body select {
  font-weight: 500;
  font-size: 1rem;
  line-height: 163%;
  color: #22262f;
  text-decoration: none;
  margin: 0;
}

body .dark-mode p,
body .dark-mode input,
body .dark-mode select {
  color: #fff;
}

body button,
body a,
a.nav-link,
body a:hover,
a.nav-link:hover {
  font-weight: 700;
  font-size: 1rem;
  line-height: 163%;
  color: #22262f;
  cursor: pointer;
}

body .dark-mode button,
body .dark-mode a,
.dark-mode a.nav-link,
body .dark-mode a:hover,
.dark-mode a.nav-link:hover {
  color: #fff;
}

body span {
  font-weight: 500;
  font-size: 16px;
  line-height: 163%;
  color: #22262f;
}

body .dark-mode span {
  color: #fff;
}

.preamble {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 163%;
  text-align: left;
  color: #22262f;
}

.dark-mode .preamble {
  color: #fff;
}

body textarea {
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  color: #22262f;
  border: 2px solid #d3d3d4;
  min-height: 94px;
  outline: none;
}

body .dark-mode textarea {
  color: #ffffff;
}

/* Assets */
section {
  padding: 80px 0;
}

body select,
select.form-select {
  cursor: pointer;
}

body input,
input.form-control,
body select,
select.form-select {
  padding: 8px 24px;
  margin: 0;
  border: 2px solid #d3d3d4;
  border-radius: 1000px;
  font-weight: 500;
  font-size: 16px;
  color: #858585;
  background-color: #ffffff;
  max-height: 42px;
}

body .dark-mode input,
.dark-mode input.form-control,
body .dark-mode select,
.dark-mode select.form-select {
  color: #ffffff;
  background-color: #43474e;
}

input:focus-visible,
input.form-control:focus-visible,
select:focus-visible,
select.form-select:focus {
  outline: none;
  box-shadow: none;
  color: #858585;
  background-color: #ffffff;
  border: 2px solid #d3d3d4;
}

.dark-mode input:focus-visible,
.dark-mode input.form-control:focus-visible,
.dark-mode select:focus-visible,
.dark-mode select.form-select:focus {
  color: #ffffff;
  background-color: #43474e;
}

input.form-control::placeholder {
  color: #d3d3d4;
}

select.form-select {
  background-image: url(assets/images/dropdown-icon.svg);
}

select.form-select {
  background-size: 16px auto;
  max-height: 42px;
  padding: 8px 24px 8px 24px;
}

.btn-tsl {
  padding: 8px 40px;
  background: #83bb25;
  color: #22262f;
  border: none;
  border-radius: 1000px;
  min-height: 40px;
  transition: 300ms background-color;
}

.btn-tsl:hover {
  background: #76a821;
}

.btn-outline-tsl {
  padding: 8px 40px;
  background: transparent;
  color: #22262f;
  border: 2px solid #83bb25;
  border-radius: 1000px;
  min-height: 40px;
  transition: 300ms background-color;
  text-decoration: none;
}

.btn-outline-tsl:hover {
  background: #76a821;
  color: #fff;
  border: 2px solid #76a821;
}

.link-tsl {
  text-decoration: none;
  color: #83bb25 !important;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: 300ms background-color;
}

.link-tsl:hover {
  color: #76a821 !important;
}

.card-style {
  background-color: #ffffff;
  color: #22262f;
  padding: 2rem;
  border-radius: 4px;
}

.dark-mode .card-style {
  background-color: #43474e;
  color: #fff;
}

.card-style hr {
  border-color: #858585;
  margin: 0;
  margin-bottom: 2rem;
  opacity: 1;
}

.card-style .regional-input {
  max-width: 408px;
}

.tooltip-btn {
  background-color: #83bb25;
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
  border-radius: 9999px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.tooltip-btn svg {
  height: 14px !important;
  color: #fff;
}

/* Media queries */
@media (max-width: 991.98px) {
  /* Typography mobile */
  body h1 {
    font-size: 1.75rem;
  }

  body h2 {
    font-size: 1.25rem;
  }

  body h3 {
    font-size: 1.125rem;
  }

  /* Assets */
  section {
    padding: 48px 0;
  }
}

@media (max-width: 767.98px) {
  /* Assets */
  .btn-tsl {
    padding: 8px 24px;
  }
}
